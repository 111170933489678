import React from 'react';
import * as styles from './Hero.module.scss';

const PaddingTypes = {
    small: styles.HeroBodySmall,
    medium: styles.HeroBodyMedium,
    large: styles.HeroBodyLarge
};

const ContainerWidthTypes = {
    small: 'containSmall',
    default: '',
    medium: 'containMedium',
    large: 'containLarge'
};

const JustifyItemsTypes = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
};

interface HeroBodyProps {
    children: React.ReactNode,
    containerWidth?: keyof typeof ContainerWidthTypes,
    contain?: boolean,
    className?: string,
    padding?: keyof typeof PaddingTypes,
    justifyItems?: keyof typeof JustifyItemsTypes,
}

const HeroBody = ({
    children, className, containerWidth = 'default', padding = 'small', justifyItems = 'left', contain = true
}: HeroBodyProps): JSX.Element => (
    <div className={`${styles.HeroBody} ${PaddingTypes[padding]} ${JustifyItemsTypes[justifyItems]} ${(contain) ? 'contain' : null} ${(containerWidth) ? `${ContainerWidthTypes[containerWidth]}` : null} ${className}`}>
        {children}
    </div>
);

export default HeroBody;
