import React from 'react';

interface HeroTitleProps {
    children: React.ReactNode,
    className?: string,
    titleType?: keyof typeof TitleTypes,
}

const TitleTypes = {
    default: 'text-title',
    subtitle: 'text-subtitle',
};


const HeroTitle = ({ children, className, titleType = 'default' }: HeroTitleProps): JSX.Element => (
    <h1 className={`${TitleTypes[titleType]} valign--middle ${className}`}>{children}</h1>
);

export default HeroTitle;
