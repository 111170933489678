import React from 'react';
import * as styles from './Hero.module.scss';

interface HeroFooterProps {
    children: React.ReactNode,
    containerWidth?: 'small'|'medium'|'large',
    className?: string,
    justifyItems?: string,
}


const HeroFooter = ({
    children, className = '', containerWidth, justifyItems = ''
}: HeroFooterProps): JSX.Element => {
    const getAlignment = () => {
        switch (justifyItems) {
        case 'left':
            return 'justify-start';
        case 'center':
            return 'justify-center';
        case 'right':
            return 'justify-end';
        case 'center-right':
            return 'justify-center md:justify-end';
        default:
            return '';
        }
    };

    const getContainer = (): string => ((containerWidth) ? `contain contain${containerWidth.charAt(0).toUpperCase()}${containerWidth.slice(1)}` : '');

    return (
        <div className={`${className} w-full`}>
            <div className={`${styles.HeroFooter} ${getAlignment()} ${getContainer()} `}>
                {children}
            </div>
        </div>

    );
};

export default HeroFooter;
