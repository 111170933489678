import React, { useState } from 'react';

import Vimeo from '@u-wave/react-vimeo';
import { Player } from '@vimeo/player';

import { Waypoint } from 'react-waypoint';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './VideoBackground.module.scss';

import { VimeoEntity } from '../../types/contentfulModels/VimeoEntity';

export interface VideoBackgroundProps {
    parentRef: React.MutableRefObject<HTMLLIElement|HTMLDivElement>;
    videoData: VimeoEntity;
    triggersOnWaypoint?: boolean;
    blurThumbnail?: boolean;
}

const getVideoWrapClass = (vw: number, vh: number, parentRef: VideoBackgroundProps['parentRef']) => {
    if (!parentRef.current) return '';
    const tile = parentRef.current;

    return ((vw / vh) > (tile.clientWidth / tile.clientHeight)) ? styles.VideoBackgroundAssetFH : styles.VideoBackgroundAssetFW;
};

const useWindowResizeThrottled = (onResize: ()=> void, delay = 200) => {
    const timeout = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        const windowResize = () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                if (typeof onResize === 'function') {
                    onResize();
                }
            }, delay);
        };

        window.addEventListener('resize', windowResize);

        windowResize();

        /** on unmount clean up the timeout and the listener */
        return () => {
            if (timeout.current) { clearTimeout(timeout.current); }
            window.removeEventListener('resize', windowResize);
        };
    }, []);
};

const VideoBackground = ({
    parentRef,
    videoData,
    triggersOnWaypoint = true,
    blurThumbnail = false,
}: VideoBackgroundProps): JSX.Element => {
    const [currentPlayer, setCurrentPlayer] = useState<Player|null>(null);
    const [playerIsPaused, setPlayerIsPaused] = useState(true);
    const [firstPlayTriggered, setFirstPlayTriggered] = useState(false);

    const [cssToApplyToWrap, setCssToApplyToWrap] = React.useState('');

    useWindowResizeThrottled(() => {
        setCssToApplyToWrap(getVideoWrapClass(videoData.vimeoWidth, videoData.vimeoHeight, parentRef));
    }, 200);

    React.useEffect(() => {
        setCssToApplyToWrap(getVideoWrapClass(videoData.vimeoWidth, videoData.vimeoHeight, parentRef));
    }, [videoData.vimeoWidth, videoData.vimeoHeight, parentRef]);

    const playVideo = (): void => {
        if (currentPlayer) {
            if (playerIsPaused) {
                currentPlayer.play();
                setPlayerIsPaused(false);
            }
        }
    };

    const pauseVideo = (): void => {
        if (currentPlayer) {
            if (!playerIsPaused) {
                currentPlayer.pause();
                setPlayerIsPaused(true);
            }
        }
    };
    return (
        <Waypoint onEnter={(triggersOnWaypoint) ? playVideo : undefined} onLeave={(triggersOnWaypoint) ? pauseVideo : undefined} topOffset="33%" bottomOffset="33%">
            <div className={`${styles.VideoBackground}`}>
                <Vimeo
                    video={videoData.vimeoId}
                    loop
                    volume={0}
                    showByline={false}
                    showPortrait={false}
                    showTitle={false}
                    autoplay={!triggersOnWaypoint}
                    autopause={false}
                    controls={false}
                    className={`${styles.VideoBackgroundAsset}  ${cssToApplyToWrap}`}
                    style={{ aspectRatio: `${(videoData.vimeoWidth / videoData.vimeoHeight)}/1` }}
                    onPlay={() => { setFirstPlayTriggered(true); }}
                    onReady={setCurrentPlayer}
                />
                {
                    (videoData.vimeoThumbnail && !firstPlayTriggered)
                        ? (
                            <GatsbyImage
                                image={videoData.vimeoThumbnail.gatsbyImageData}
                                alt={(videoData.vimeoDescription) ? videoData.vimeoDescription : videoData.vimeoTitle}
                                className={`${styles.VideoBackgroundThumbnail} ${cssToApplyToWrap} ${(blurThumbnail) ? styles.VideoBackgroundThumbnailBlur : ''}`}
                                style={{ aspectRatio: `${(videoData.vimeoWidth / videoData.vimeoHeight)}/1` }}
                            />
                        )
                        : null
                }
            </div>
        </Waypoint>
    );
};

export default VideoBackground;

