import React from 'react';

interface HeroSubtitleProps {
    children: React.ReactNode,
    className?: string,
}

const HeroSubtitle = ({ children, className }: HeroSubtitleProps): JSX.Element => (
    <p className={`text-paragraph ${className}`}>{children}</p>
);

export default HeroSubtitle;
