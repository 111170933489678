import React, { useRef } from 'react';

import { VimeoEntity } from '../../types/contentfulModels/VimeoEntity';

import generateBackground from '../../utilities/generateBackground';

import * as styles from './Hero.module.scss';

import HeroTitle from './HeroTitle';
import HeroSubtitle from './HeroSubtitle';
import HeroBody from './HeroBody';
import HeroFooter from './HeroFooter';

import VideoBackground from '../VideoBackground/VideoBackground';


const HeightTypes = {
    small: styles.HeroSmall,
    medium: styles.HeroMedium,
    large: styles.HeroLarge,
    xLarge: styles.HeroXLarge
};

interface HeroProps {
    background?: string,
    videoData?: VimeoEntity,
    className?: string,
    height?: keyof typeof HeightTypes,
    children: React.ReactNode,
}

const Hero = ({
    height = 'small', background, className, children, videoData,
}: HeroProps): JSX.Element => {
    const heroRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    return (
        <header ref={heroRef} className={`${styles.Hero} ${className} ${HeightTypes[height]}`} style={{ backgroundImage: generateBackground({ background }) }}>
            {
                (videoData)
                    ? (
                        <div className={styles.HeroAsset}>
                            <VideoBackground
                                videoData={videoData}
                                triggersOnWaypoint={false}
                                parentRef={heroRef}
                                blurThumbnail
                            />
                        </div>
                    )
                    : null
            }
            {children}
        </header>
    );
};

Hero.Title = HeroTitle;
Hero.Subtitle = HeroSubtitle;
Hero.Body = HeroBody;
Hero.Footer = HeroFooter;

export default Hero;


