// extracted by mini-css-extract-plugin
export var Hero = "Hero-module--Hero--QH4at";
export var HeroAsset = "Hero-module--HeroAsset--gB95k";
export var HeroBody = "Hero-module--HeroBody--0tWMT";
export var HeroBodyLarge = "Hero-module--HeroBodyLarge--iXz9f";
export var HeroBodyMedium = "Hero-module--HeroBodyMedium--Yl9H9";
export var HeroBodySmall = "Hero-module--HeroBodySmall--+YLLJ";
export var HeroFooter = "Hero-module--HeroFooter--w7R4n";
export var HeroLarge = "Hero-module--HeroLarge--OFi60";
export var HeroMedium = "Hero-module--HeroMedium--rmQJe";
export var HeroSmall = "Hero-module--HeroSmall--yGzi5";
export var HeroXLarge = "Hero-module--HeroXLarge--kZDn8";