interface GenerateBackgroundProps {
    background?: string|undefined,
    opacityStart?: `${number}%`,
    opacityEnd?: `${number}%`,
}

const generateBackground = ({ background, opacityStart = '85%', opacityEnd = '85%' }: GenerateBackgroundProps): string|undefined => {
    if (!background) {
        return undefined;
    }

    const bgImages: string[] = [];

    bgImages.push(`linear-gradient(to top, hsla(221,30%,15%,${opacityStart}), hsla(221,30%,15%,${opacityEnd}))`);

    if (background) {
        bgImages.push(`url("${background}")`);
    }

    return bgImages.join(', ');
};

export default generateBackground;
